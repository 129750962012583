import * as API from '@/api/index'

export default {
    skinUsePeriod:params =>{
        return API.POST('api/skinUsePeriod/all',params)
    },
    create:params =>{
        return API.POST('api/skinUsePeriod/create',params)
    },
    update:params =>{
        return API.POST('api/skinUsePeriod/update',params)
    },
    Unitlist:params=>{
        return API.POST("api/skinUsePeriodUnit/list",params)
    }
}